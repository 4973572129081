// ** React Imports
import { useEffect, useState } from 'react'

// ** Next Imports
import Link from 'next/link'

// ** MUI Components
import LoadingButton from '@mui/lab/LoadingButton'

/* import Divider from '@mui/material/Divider' */

import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import useMediaQuery from '@mui/material/useMediaQuery'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Typography, { TypographyProps } from '@mui/material/Typography'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Hooks
import { useAuth } from 'src/hooks/useAuth'
import { useSettings } from 'src/@core/hooks/useSettings'
import { useIntl } from 'react-intl'

// ** Source code Imports
import { createAlert } from 'src/state-manager/snackbar'
import { errorMessagesOpts } from './opts'
import { Logotype } from 'src/layouts/components/icons'
import apiEndpoints from 'src/configs/api'

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    width: '100%'
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { mt: theme.spacing(8) }
}))

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

const defaultValues = {
  password: '',
  email: ''
}

interface FormData {
  email: string
  password: string
}

const LoginForm = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  // ** Hooks
  const auth = useAuth()
  const theme = useTheme()
  const { settings } = useSettings()
  const mediumMatch = useMediaQuery(theme.breakpoints.down('md'))
  const { formatMessage } = useIntl()

  // ** Vars
  const { mode } = settings

  const schema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(formatMessage({ id: 'email-error' })),
    password: yup.string().min(5, formatMessage({ id: 'error-password' }))
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const fieldsData = useWatch({ control })

  useEffect(() => {
    const subscription = watch(() => {
      if (errors?.root?.serverError) clearErrors('root.serverError')
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const onSubmit = (data: FormData) => {
    const { email, password } = data

    setLoading(true)

    auth.login({ email, password, rememberMe }, ({ response }: any) => {
      const error = response.data.message || response.data.error

      setLoading(false)

      if (response.data.error) {
        const errorMessage = errorMessagesOpts[error as string]
        const message: any = errorMessage ? formatMessage({ id: errorMessage }) : error

        setError('root.serverError', {
          type: 'manual',
          message
        })

        createAlert({
          autoHideDuration: 3000,
          action: false,
          open: true,
          message: { type: 'formatMessage', value: 'authorization-error' },
          variant: 'filled',
          alertSeverity: 'error',
          actionButton: false
        })

        return
      }
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          overflow: 'hidden',
          zIndex: -1
        }}
      >
        <Box
          component='img'
          src='/images/ellipse-bg.png'
          sx={{
            minWidth: 2890,
            width: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </Box>
      <BoxWrapper
        sx={{
          pt: 12,
          pb: 12,
          pl: mediumMatch ? 4 : 6,
          pr: mediumMatch ? 4 : 6,
          maxWidth: 450,
          backgroundColor: 'background.paper',
          borderRadius: theme.spacing(2.5),
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 0
          }
        }}
      >
        <Box sx={{ maxWidth: 450, m: '0 auto' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 6
            }}
          >
            <Link href={apiEndpoints.landingUrl}>
              <Logotype height={54} mode={mode} />
            </Link>
          </Box>
          <Box sx={{ mb: 6, textAlign: 'center' }}>
            <TypographyStyled variant='h5'>{formatMessage({ id: 'signin-title' })}</TypographyStyled>
          </Box>
          <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <Controller
                name='email'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    label={formatMessage({ id: 'email' })}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.email)}
                    placeholder='example@gosilk.ru'
                  />
                )}
              />
              {errors.email && (
                <FormHelperText sx={{ color: 'error.main', ml: 0 }}>{errors.email.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                {formatMessage({ id: 'password' })}
              </InputLabel>
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    label={formatMessage({ id: 'password' })}
                    onChange={onChange}
                    id='auth-login-v2-password'
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: 'error.main', ml: 0 }}>{errors.password.message}</FormHelperText>
              )}
            </FormControl>
            <Box
              sx={{ mb: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
              <FormControlLabel
                label={formatMessage({ id: 'remember-me' })}
                control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
              />
              <LinkStyled href='/forgot-password'>{formatMessage({ id: 'forgot-password-question' })}</LinkStyled>
            </Box>
            {errors?.root?.serverError && (
              <FormHelperText sx={{ color: 'error.main', mb: 4 }}>{errors.root.serverError.message}</FormHelperText>
            )}
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mb: 7 }}
              disabled={!fieldsData.email || !fieldsData.password}
              loading={loading}
            >
              {formatMessage({ id: 'login' })}
            </LoadingButton>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
              <Typography variant='body2' sx={{ mr: 2 }}>
                {formatMessage({ id: 'new-on-platform-question' })}
              </Typography>
              <Typography variant='body2'>
                <LinkStyled href='/register'>{formatMessage({ id: 'create-account' })}</LinkStyled>
              </Typography>
            </Box>
            {/* todo: нужно подключать авторизацию через соц.сети */}
            {/* <Divider sx={{ my: theme => `${theme.spacing(5)} !important` }}>
              {formatMessage({ id: 'or' })}
            </Divider>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton
                href='/'
                component={Link}
                sx={{ color: '#497ce2' }}
                onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}
              >
                <Icon icon='mdi:facebook' />
              </IconButton>
              <IconButton
                href='/'
                component={Link}
                sx={{ color: '#1da1f2' }}
                onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}
              >
                <Icon icon='mdi:twitter' />
              </IconButton>
              <IconButton
                href='/'
                component={Link}
                onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}
                sx={{ color: theme => (theme.palette.mode === 'light' ? '#272727' : 'grey.300') }}
              >
                <Icon icon='mdi:github' />
              </IconButton>
              <IconButton
                href='/'
                component={Link}
                sx={{ color: '#db4437' }}
                onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}
              >
                <Icon icon='mdi:google' />
              </IconButton>
            </Box> */}
          </form>
        </Box>
      </BoxWrapper>
    </Box>
  )
}

export default LoginForm
